import translate from "@/utils/translation"
import { Button } from "@mui/material"
import { useRouter } from "next/router" // Ensure useRouter is imported
import { TagManager } from "react-gtm-module" // Ensure TagManager is imported if used
import { Link } from "next-translate-routes"
import React from "react"
import { GraphUp, ShieldCheck, Houses, ArrowRight } from "react-bootstrap-icons"
import styled from "styled-components"

const Benefits = () => {
  const router = useRouter()
  const session = null // Replace with your actual session logic

  return (
    <Wrapper className="container p-0">
      <div className="benefits-row row m-0 mt-md-3">
        <div className="col-12 text-center mb-md-5 mb-2 order-1 order-md-1 mt-md-0">
          <h2>{translate("homepage_benefits_title")}</h2>
          <p className="description">
            {translate("homepage_benefits_subtitle")}
          </p>
        </div>
        <div className="col-12 col-md-6 order-2 order-md-2 mt-4 mt-md-0">
          <div className="image text-end me-md-5">
            <img
              src="/daniel-phone-v2.png"
              alt="Investment"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 order-3 order-md-3 mt-3 mt-md-0">
          <div className="row">
            {/* Consistent passief inkomen */}
            <div className="col-md-12 p-md-1 mt-3 mt-md-0 mt-xl-3">
              <div className="item d-flex">
                <div className="icon">
                  <GraphUp size={50} />
                </div>
                <div className="content ">
                  <h4>{translate("homepage_benefits_1_title")}</h4>
                  <p>{translate("homepage_benefits_1_text")}</p>
                </div>
              </div>
            </div>

            {/* Zekerheden */}
            <div className="col-md-12 p-md-1 mt-3 mt-md-4">
              <div className="item d-flex">
                <div className="icon">
                  <ShieldCheck size={50} />
                </div>
                <div className="content ">
                  <h4>{translate("homepage_benefits_3_title")}</h4>
                  <p>{translate("homepage_benefits_3_text")}</p>
                </div>
              </div>
            </div>

            {/* Consistent rendement */}
            <div className="col-md-12 p-md-1 mt-3 mt-md-4">
              <div className="item d-flex">
                <div className="icon">
                  <Houses size={50} />
                </div>
                <div className="content">
                  <h4>{translate("homepage_benefits_4_title")}</h4>
                  <p>{translate("homepage_benefits_4_text")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-md-1 mt-4 mt-md-4 text-center text-md-start ">
              <Button
                variant="contained"
                size={"large"}
                className="rounded-pill"
                onClick={() => {
                  session?.profile
                    ? router.push(`/listings/`)
                    : router.push("/auth/register")
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "homepage_howitworks_button",
                      user_id: session?.uid,
                    },
                  })
                }}
              >
                {translate("homepage_usps_button")}
                <ArrowRight className="ms-1" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Benefits

const Wrapper = styled.div`
  margin: 6rem auto 0;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  h4 {
    line-height: 1.5rem;
  }

  .description {
    max-width: 500px;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  .item {
    height: 100%;
    max-width: 500px;
    padding: 1rem 1rem 0rem !important;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    flex-grow: 1;
    display: flex;
    align-items: start;
    background: #fff; /* white background for item */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      margin: 0 1rem;
      padding: 1rem 0 0 0;
    }

    h4 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }

    p {
      margin-top: 0;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    background-color: #fbe8d2; /* Orange background for icon */
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Rounded icon container */

    svg {
      padding: 0.6rem;
      color: #ff8500; /* Orange color for icon */
    }
  }

  .deeplink {
    font-size: 0.9rem;
    color: #656565;

    a {
      color: #656565;
    }
  }

  .image {
    img {
      width: 580px;
      border-radius: 20px;
    }

    @media (max-width: 768px) {
      text-align: center;
      width: 90%;
      margin: 0 auto;
    }
  }

  .content {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 0;

    margin: 4rem auto 0;

    .item {
      padding: 1rem;
    }

    h2 {
      font-size: 1.55rem;
      line-height: 1.25;
    }

    h4 {
      font-size: 1.1rem;
    }

    .description {
      p {
        padding: 0 1rem;
      }
    }
  }
`
