import React, { useState, useRef, useEffect } from "react"
import { Avatar, Button } from "@mui/material"
import { ArrowRight, ChevronRight, StarFill } from "react-bootstrap-icons"
import styled from "styled-components"
import background from "@/public/waves.svg"
import { Link, useRouter } from "next-translate-routes"
import { useSession } from "next-auth/react"
import TagManager from "react-gtm-module"
import translate from "@/utils/translation"

const VideoV2 = () => {
  const { data: session } = useSession()
  const router = useRouter()

  const [isInView, setIsInView] = useState(false)
  const videoRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsInView(true)
            observer.unobserve(entry.target)
          }
        })
      },
      { threshold: 0.1 }
    )

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current)
      }
    }
  }, [])

  return (
    <Wrapper id="how-it-works">
      <div className="container mt-md-5">
        <div className="row m-0">
          <div className="col-12 mt-3 mt-md-0 col-md-6 pt-md-0 pb-3 order-2 order-md-1 p-3 p-md-0 pt-0">
            <div className="box">
              <div className="d-flex justify-content-center video">
                <div className="image-wrapper" ref={videoRef}>
                  {isInView && (
                    <video autoPlay loop muted playsInline>
                      <source
                        src="/video/General_video_Valvest.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              </div>
              <div className="pb-3 d-flex questions d-none d-md-flex">
                <Avatar
                  src="/pages/about/employees/tom-2.jpg"
                  sx={{ height: 45, width: 45 }}
                />
                <div className="ms-3">
                  <h5>{translate("homepage_video_questions_title_test")}</h5>
                  <p>
                    {translate("homepage_video_questions_text_1_test")}{" "}
                    <span onClick={() => router.push("/blog/how-it-works")}>
                      {translate("homepage_video_questions_text_2_test")}
                    </span>{" "}
                    {translate("homepage_video_questions_text_3_test")}{" "}
                    <span onClick={() => router.push("/helpcenter")}>
                      {translate("homepage_video_questions_text_4_test")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="socialProof d-none d-md-block mt-md-4 mt-0">
              <p className="me-md-3 review">
                {translate("homepage_video_review_text_test")}
                <span className="ms-2 stars" style={{ marginTop: "-10px" }}>
                  <StarFill color="#FFB802" />
                  <StarFill color="#FFB802" />
                  <StarFill color="#FFB802" />
                  <StarFill color="#FFB802" />
                  <StarFill color="#FFB802" />
                </span>{" "}
                <span className="author">
                  - {translate("homepage_video_review_author")}
                </span>
              </p>
            </div>
            <div className="socialProof d-md-none mt-md-4 mt-0">
              <div className="d-block d-md-none">
                <p className="mb-1">
                  {translate("homepage_video_review_text_test")}
                </p>
                <div>
                  <span className="stars" style={{ marginTop: "-10px" }}>
                    <StarFill color="#FFB802" />
                    <StarFill color="#FFB802" />
                    <StarFill color="#FFB802" />
                    <StarFill color="#FFB802" />
                    <StarFill color="#FFB802" />
                  </span>{" "}
                  <span className="author">
                    - {translate("homepage_video_review_author")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 why order-md-2 order-1 m-0">
            <h2>
              {translate("homepage_video_title_1_test")}
              <span className="highlight">
                {" " + translate("homepage_video_title_2_test") + " "}
              </span>
              {translate("homepage_video_title_3_test")}
            </h2>
            {/* <p className="description">
              {translate("homepage_video_text_test")}{" "}
            </p> */}
            <div
              className={
                router?.locale === "en"
                  ? "line"
                  : router?.locale === "es"
                  ? "line line_es"
                  : "line line_nl"
              }
            />
            <div className="step d-flex mt-4 mt-md-4">
              <div className="number">1</div>
              <div className="content">
                <h3>{translate("homepage_video_subtitle_1_test")}</h3>
                <p>{translate("homepage_video_text_1_test")}</p>
              </div>
            </div>
            <div className="step d-flex">
              <div className="number">2</div>
              <div className="content">
                <h3>{translate("homepage_video_subtitle_2_test")}</h3>
                <p>{translate("homepage_video_text_2_test")}</p>
              </div>
            </div>
            <div className="step d-flex">
              <div className="number">3</div>
              <div className="content">
                <h3>{translate("homepage_video_subtitle_3_test")}</h3>
                <p>{translate("homepage_video_text_3_test")}</p>
              </div>
            </div>
          </div>
          <div className="text-center mt-md-5 order-md-3 order-3 mt-3">
            <Button
              variant="contained"
              size={"large"}
              className="rounded-pill"
              onClick={() => {
                session?.profile
                  ? router.push(`/listings/`)
                  : router.push("/auth/register"),
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "homepage_howitworks_button",
                      user_id: session?.uid,
                    },
                  })
              }}
            >
              {translate("homepage_usps_button")}
              <ArrowRight className="ms-1" />
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default VideoV2

const Wrapper = styled.div`
  background-color: #fbfaf8;
  padding: 3rem 0 5rem;
  position: relative;

  :before {
    content: "";
    background-size: cover;
    position: absolute;
    background-image: url(${background.src});
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.45;
    background-repeat: no-repeat;
    background-position: bottom;

    @media (max-width: 768px) {
      opacity: 0.25;
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;
    z-index: 1;
  }

  video {
    width: 100%;
    height: 100%;
  }

  h2 {
    font-size: 2rem;
    text-align: start;
  }

  .why {
    position: relative;

    .line {
      width: 2px;
      height: 270px;
      background-color: #fb8501;
      margin-right: 1rem;
      position: absolute;
      left: 27px;
      z-index: 0;
      top: 80px;
    }

    .highlight {
      color: #fb8501;
    }

    .step {
      position: relative;
      margin-bottom: 2.5rem;

      :last-child {
        margin-bottom: 0;
      }

      .number {
        margin-top: 0.2rem;
        background-color: #fb8501;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        margin-right: 1rem;
        color: white;
        padding: 1rem;
      }

      h3 {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }

      p {
        margin-bottom: 0.5rem;
      }

      @media (max-width: 768px) {
        margin-bottom: 1.5rem;
      }
    }
  }

  a {
    color: #656565;
    text-decoration: none;
    font-size: 0.875rem;
    margin: 0;
  }

  .content {
    margin-top: 0.3rem;

    p {
      max-width: 440px;
    }
  }

  .socialProof {
    width: 90%;
    margin: 1rem 0 0;

    @media (max-width: 768px) {
      margin-top: 1rem !important;
      width: 100%;
    }

    .stars svg {
      margin-top: -2px;
    }
  }

  .box {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 0;
    position: relative;
    opacity: 1 !important;

    @media (max-width: 768px) {
      width: 100% !important;
    }

    .video {
      @media (max-width: 768px) {
        margin-top: 1.5rem;
      }
    }

    .image-wrapper {
      max-width: 100%;

      img {
        border-radius: 10px 10px 0 0;
      }

      video {
        display: block; /* Removes any additional space (e.g., white space below the video) */
        border-radius: 10px 10px 0 0; /* Same border-radius as container to ensure rounded corners */
        object-fit: cover; /* Ensures the video covers the container without distorting the aspect ratio */
      }

      @media (max-width: 768px) {
        video {
          border-radius: 10px;
        }
      }

      cursor: pointer;
      position: relative;

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: white;
        cursor: pointer;
      }
    }

    .questions {
      padding: 1rem 1.5rem;
    }

    h5 {
      font-size: 1rem;
      margin: 0.25rem 0 0.25rem 0;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
      color: #656565;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .socialProof {
    font-size: 0.875rem;
    color: #656565;

    .author {
      font-style: italic;
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 0 4rem;

    h2 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .modal-wrapper {
      .modal-content {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  /* Adjustments for different screen sizes */

  @media (max-width: 1400px) {
    .line {
      top: 80px !important;
      height: 290px !important;
    }

    .line_nl {
      top: 90px !important;
      height: 280px !important;
    }
  }

  @media (max-width: 1300px) {
    .line {
      top: 70px !important;
      height: 290px !important;
    }

    .line_nl {
      height: 300px !important;
    }
  }

  @media (max-width: 1200px) {
    .line {
      top: 90px !important;
      height: 280px !important;
    }

    .line_nl {
      top: 90px !important;
      height: 280px !important;
    }
  }

  @media (max-width: 1100px) {
    .line {
      top: 90px !important;
      height: 270px !important;
    }

    .line_nl {
      height: 270px !important;
    }
  }

  @media (max-width: 1000px) {
    .line {
      top: 80px !important;
      height: 310px !important;
    }

    .line_nl {
      top: 70px !important;
      height: 320px !important;
    }

    .line_es {
      top: 170px !important;
      height: 680px !important;
    }
  }

  @media (max-width: 768px) {
    .line {
      top: 80px !important;
      height: 230px !important;
    }

    .line_nl {
      top: 70px !important;
      height: 200px !important;
    }
  }

  @media (max-width: 600px) {
    .line {
      top: 140px !important;
      height: 280px !important;
    }

    .line_nl {
      top: 130px !important;
      height: 300px !important;
    }
  }

  @media (max-width: 500px) {
    .line {
      top: 130px !important;
      height: 300px !important;
    }

    .line_nl {
      top: 150px !important;
      height: 300px !important;
    }
  }

  @media (max-width: 440px) {
    .line {
      top: 140px !important;
      height: 460px !important;
      display: none !important;
    }
  }

  @media (max-width: 400px) {
    .line {
      top: 140px !important;
      height: 500px !important;
      display: none !important;
    }
  }

  @media (max-width: 370px) {
    .line {
      display: none !important;
    }
  }
`
